import DateHelper from "~helpers/DateHelper";
import Utils from "~helpers/Utils";

export function socketMessageToState(wsMessage) {
  let dataUrl = wsMessage.url || "";
  const fileNameByUrl = wsMessage.url?.split("/").pop();
  const msg = {
    id: wsMessage.id || wsMessage.message_id || wsMessage.sender_id,
    sessionId: wsMessage.session_id,
    format: wsMessage.format?.toLowerCase(),
    type: wsMessage.type?.toLowerCase(),
    text: wsMessage.text,
    imageUrl: dataUrl,
    fileUrl: dataUrl,
    fileName: wsMessage.name || wsMessage.file_name || fileNameByUrl,
    fileType: dataUrl ? Utils.getFileType(dataUrl) : "",
    choices: (wsMessage.choices || []).map((choice) => ({
      text: choice.text,
      value: choice.value,
    })),
    position:
      wsMessage.type === "dynamic_carousel" ? "center" : wsMessage.sender_type === "CUSTOMER" ? "right" : "left",
    carouselData: wsMessage.carousel_data,
    messageTime: DateHelper.getDateTime(wsMessage.message_time || wsMessage.created || wsMessage.time), // FIX: Change time

    sender: wsMessage.sender_type,
    isSent: wsMessage.isSent,
    likeStatus: wsMessage.like_status,
    historyId: wsMessage.history_id || wsMessage.historyId,
    ...(wsMessage?.latitude &&
      wsMessage?.longitude && {
        locationInfo: {
          name: wsMessage.name,
          address: wsMessage.address,
          latitude: wsMessage.latitude,
          longitude: wsMessage.longitude,
        },
      }),
    senderData: wsMessage.senderData,
  };
  return msg;
}
